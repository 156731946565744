import React, { useEffect, useState } from "react"

const useGenerateCaptchaString = (len = 4, ntimes = 0) => {
  const [markerCode, setMarkerCode] = useState("")

  const createCode = () => {
    var codeNum = ""
    var codeLength = len
    var random = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ]

    for (var i = 0; i < codeLength; i++) {
      var index = Math.floor(Math.random() * 36)
      codeNum += random[index]
    }
    const result = codeNum.toLowerCase()
    setMarkerCode(result)
  }

  useEffect(() => {
    createCode()
  }, [ntimes])

  return markerCode
}

export default useGenerateCaptchaString
