import React, { useState, useEffect } from "react"
import useGenerateCaptchaString from "../../hooks/use-generateCaptchaString"
import _ from "lodash"

export default function GetCaptchaElement(props) {
  const [captchaRefreshTimer, setCaptchaRefreshTimer] = useState(0)
  const markerCode = useGenerateCaptchaString(4, captchaRefreshTimer)

  function clearCanvas(context, canvas) {
    context.clearRect(0, 0, canvas.width, canvas.height)
    var w = canvas.width
    canvas.width = 1
    canvas.width = w
  }

  const drawcode = () => {
    var canvasBtn = document.getElementById("mycanvas")
    var context = canvasBtn.getContext("2d")
    clearCanvas(context, canvasBtn)

    context.font = "20px Lato"
    context.fillStyle = "#495057"
    context.textAlign = "center"
    context.fillText(markerCode, 35, 25)

    //Draw interference lines
    for (var l = 0; l <= 5; l++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      context.moveTo(Math.random() * 70, Math.random() * 36)
      context.lineTo(Math.random() * 70, Math.random() * 36)
      context.stroke()
    }

    //Draw interference points
    for (var p = 0; p <= 30; p++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      var x = Math.random() * 70
      var y = Math.random() * 36
      context.moveTo(x, y)
      context.lineTo(x + 1, y + 1)
      context.stroke()
    }
  }

  // Random color
  const randomColor = () => {
    var r = Math.floor(Math.random() * 256)
    var g = Math.floor(Math.random() * 256)
    var b = Math.floor(Math.random() * 256)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  useEffect(() => {
    if (markerCode.length === 0) return
    drawcode()
    props.onChange(markerCode)
  }, [markerCode])

  // useEffect(() => {
  //   if (_.isUndefined(props.captchaRefreshCounter) || _.isNull(props.captchaRefreshCounter)) return
  //   setCaptchaRefreshTimer(n => n + 1)
  // }, [props.captchaRefreshCounter])

  return (
    <canvas height="40px" id="mycanvas" onClick={() => setCaptchaRefreshTimer(n => n + 1)}></canvas>
  )
}
