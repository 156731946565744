import React, { useState, useEffect } from "react"
import { Row, Col, Button } from "reactstrap"
// import { Form, Input, FormGroup, Label } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { PropagateLoader } from "react-spinners"
import { toast } from "react-toastify"
import Captcha from "../../../common/captcha"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { isValidEmail } from "../../../../utils/common"
import { fetch_post } from "../../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"

export default function TakedownGovtCreate(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [markercode, setMarkercode] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [form, setForm] = useState({})
  const [formCopy, setFormCopy] = useState({})
  const [showFullForm, setShowFullForm] = useState(false)
  const [modalInfo, setModalInfo] = useState({ show: false, header: "", content: "" })

  const get_description_format = name => {
    var question = document.getElementById(`${name}_label`).innerText
    var val = form[name]
    return `Q). ${question}\nA). ${val}\n--------------------------------\n`
  }

  const generate_description_text = () => {
    var ret = ""
    ret += get_description_format("name")
    ret += get_description_format("emailaddress")
    ret += get_description_format("govtAgencyAndCompany")
    ret += get_description_format("contentsToBeRemoved")
    ret += get_description_format("sourceOfIllegality")
    return ret
  }

  const clearForm = () => {
    return {
      name: "",
      emailaddress: "",
      govtAgencyAndCompany: "",
      contentsToBeRemoved: "",
      sourceOfIllegality: "",
    }
  }

  const validate_form = () => {
    if (_.isEmpty(form.name)) return "Name is required"
    else if (_.isEmpty(form.emailaddress)) return "Email is required"
    else if (!isValidEmail(form.emailaddress)) return "Email address should be valid"
    else if (_.isEmpty(form.govtAgencyAndCompany)) return "All Fields are required"
    else if (_.isEmpty(form.contentsToBeRemoved)) return "All Fields are required"
    else if (_.isEmpty(form.sourceOfIllegality)) return "All Fields are required"
    else if (_.isEmpty(inputCode)) return "Captcha code is required"
    else if (inputCode !== markercode) return "Incorrect captch code entered"
    return null
  }

  const hande_change = e => {
    let temp = JSON.parse(JSON.stringify(form))
    temp[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
    setForm(temp)
    // console.log(temp)
  }

  const has_changes = () => {
    return JSON.stringify(form) !== JSON.stringify(formCopy)
  }

  const cancel_changes = () => {
    let temp = JSON.parse(JSON.stringify(clearForm()))
    setForm(temp)
    setFormCopy(temp)
  }

  const getMarkerCode = code => {
    console.log("marker code is", code)
    setMarkercode(code)
  }

  const reset_form = () => {
    var obj = clearForm()
    setForm(obj)
    setFormCopy(obj)
    setInputCode("")
  }

  const submit_form = async () => {
    var res = validate_form()
    var token = await getAccessTokenSilently()
    var description = generate_description_text()
    console.log(description)

    if (res !== null) {
      toast(`${res}`, {
        type: "error",
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } else {
      fetch_post(`ui/support/dmca/takedown/government/create`, { theFormData: description }, token)
        .then(response => {
          console.log(response)
          if (response.status.code === 201) {
            toast(`${response.status.description}`, {
              type: "success",
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            reset_form()
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while creating takedown ticket from: ${error}`)
        })
    }
  }

  const open_modal = (header, content) => {
    setModalInfo({
      show: true,
      header: header,
      content: content,
    })
  }

  const close_modal = () => {
    setModalInfo({
      show: false,
      header: null,
      content: null,
    })
  }

  useEffect(() => {
    reset_form()
  }, [])

  return (
    <>
      {loading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          sm={{ size: 4, offset: 4 }}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      <div>
        <p>
          In order to prevent unnecessary processing delays, please note the following before
          submitting your notice:
        </p>
        <ul>
          <li>Don't send duplicate notices.</li>
          <li>
            If you believe multiple files or repositories are in violation, include links to those
            files or repositories in a single notice, instead of sending multiple notices.
          </li>
          <li>Only one point of contact from your organization should submit the notice.</li>
        </ul>
        If you are requesting on behalf of a government agency to remove content that has been
        declared unlawful in their local jurisdiction, please complete the following information:
        <div className="mb-3">
          <label id="name_label" className="form-label">
            Name
          </label>
          <input
            type="text"
            placeholder="Your name"
            className="form-control"
            id="name"
            name="name"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.name || ""}
          />
          {/* <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div> */}
        </div>
        <div className="mb-3">
          <label id="emailaddress_label" className="form-label">
            Email Address
          </label>
          <input
            type="text"
            placeholder="Your email address"
            className="form-control"
            id="emailaddress"
            name="emailaddress"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.emailaddress || ""}
          />
        </div>
        <div className="mb-3">
          <label id="govtAgencyAndCompany_label" className="form-label">
            Official Government Agency and Country:
          </label>
          <textarea
            className="form-control"
            rows="5"
            style={{ resize: "none" }}
            id="govtAgencyAndCompany"
            name="govtAgencyAndCompany"
            onChange={e => hande_change(e)}
            value={form.govtAgencyAndCompany || ""}
          ></textarea>
        </div>
        <div className="mb-3">
          <label id="contentsToBeRemoved_label" className="form-label">
            Please identify the content you are requesting to be removed. What files should be taken
            down? Please provide URLs for each file, or if the entire repository, the repository’s
            URL.
          </label>
          <textarea
            className="form-control"
            rows="5"
            style={{ resize: "none" }}
            id="contentsToBeRemoved"
            name="contentsToBeRemoved"
            onChange={e => hande_change(e)}
            value={form.contentsToBeRemoved || ""}
          ></textarea>
        </div>
        <div className="mb-3">
          <label id="sourceOfIllegality_label" className="form-label">
            Please specify the source of illegality in that jurisdiction (law or court order):
          </label>
          <textarea
            className="form-control"
            rows="5"
            style={{ resize: "none" }}
            id="sourceOfIllegality"
            name="sourceOfIllegality"
            onChange={e => hande_change(e)}
            value={form.sourceOfIllegality || ""}
          ></textarea>
        </div>
        <form className="row g-3">
          <div className="col-md-2">
            <label for="inputCity" className="form-label">
              Captcha:
            </label>
            <input
              type="text"
              name="inputCode"
              id="inputCode"
              value={inputCode}
              onChange={event => setInputCode(event.target.value)}
            />
          </div>

          <div className="col-md-2">
            <label for="inputZip" className="form-label">
              &nbsp;
            </label>
            <Captcha onChange={getMarkerCode}></Captcha>
          </div>
        </form>
        <div className="mb-3">See CodeLinaro Policies for more details.</div>
        <button
          type="button"
          onClick={() => submit_form()}
          disabled={!has_changes()}
          className="btn btn-primary"
        >
          Submit
        </button>
        &nbsp;
        <button
          type="button"
          onClick={() => reset_form()}
          disabled={!has_changes()}
          className="btn btn-info"
        >
          Reset
        </button>
      </div>
    </>
  )
}
